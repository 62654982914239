/*
*
* Users
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { Props, State } from './types';
import { Helmet } from 'react-helmet';
import { setBreadcrumb, setLoader, setTitle } from 'store/actions';
import { ContentWrapper, Icon, Table } from "components";
import { notification, Tabs } from 'antd';

import Strings from 'utils/strings';
import { API, Endpoints } from 'utils/api';
import './styles.scss';

export class Users extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			users: [],
			pendingUsers: [],
			page: 0,
			pageSize: 100,
			total: 0,
			columnSearch: null,
			globalSearch: '',
			activeTab: "users",
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(''));
		dispatch(setBreadcrumb(null));

		this.getData();
	}

	async getData(pause?: boolean) {
		const { page, pageSize, columnSearch, globalSearch, activeTab } = this.state;
		const { dispatch } = this.props;

		setTimeout(async () => {
			if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;
			dispatch(setLoader(true));

			const body = {
				filters: columnSearch ? Object.keys(columnSearch).map(elem => (
					columnSearch[elem] !== '' ? {
						field: elem,
						query: columnSearch[elem],
					} : {}
				)) : [],
				page: page,
				perPage: pageSize,
				search: globalSearch,
			};

			try {
				const response = await API.post({
					url: Endpoints.uriUsers("search"),
					data: activeTab === "users" ? body : { ...body, toConfirm: true },
				});

				if (response.ok) {
					const { users = [], total = 0 } = response.data.results;
					if (activeTab === "users") {
						this.setState({ users, total });
					} else {
						this.setState({ pendingUsers: users, total });
					}
				} else {
					notification.error({
						message: Strings.serverErrors.title,
						description: response?.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5,
					});
				}
			} catch (err) {
				notification.error({
					message: Strings.serverErrors.title,
					description: err as string,
					placement: 'bottomRight',
					duration: 5,
				});
			}

			dispatch(setLoader(false));
		}, pause ? 1000 : 0)
	}

	acceptAssociate = async (user: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriUsers(`confirm-associate/${user._id}`),
				data: { asbhipNumberConfirmed: !user.asbhipNumberConfirmed },
			});

			if (response?.ok) {
				notification.success({
					message: user.isActive ? Strings.staff.disableStaff : Strings.staff.enableStaff,
					description: user.isActive ? Strings.staff.disabled : Strings.staff.enabled,
					placement: "bottomRight",
					duration: 5
				});

				this.getData();
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}
		dispatch(setLoader(false));
	};


	get pagination() {
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				this.setState({ page, pageSize: size }, () => this.getData());
			},
		};
	}

	get filtration() {
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: true,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				this.setState({ globalSearch, page: 0 }, () => this.getData(true));
			},
			onColumnSearch: (columnSearch: string) => {
				this.setState({ columnSearch, page: 0 }, () => this.getData(true));
			},
		};
	}

	renderStatus = (elem: any) => {
		if (!!elem.value.asbhipNumber) {
			return (
				<div className={elem.value.asbhipNumberConfirmed ? "TableTag --tag-success" : "TableTag --tag-warning"}>
					<span>{elem.value.asbhipNumberConfirmed ? Strings.staff.confirmed : Strings.staff.pending}</span>
				</div>
			);
		} else return null;
	};

	renderUsers() {
		const { users = [] } = this.state;

        return (
            <Table
				title={{
					icon: "user2",
					title: Strings.sidebar.users
				}}
				data={users}
				columns={[
					{
						Header: Strings.fields.name,
						id: 'name',
						accessor: (row: any) => row.name,
					},
					{
						Header: Strings.fields.email,
						id: 'email',
						accessor: (row: any) => row.email,
					},
					{
						Header: Strings.users.associateNumber,
						id: 'asbhipNumber',
						accessor: (row: any) => row.asbhipNumber,
					},
					{
						Header: Strings.staff.status,
						id: "asbhipNumberConfirmed",
						accessor: (row: any) => (row),
						Cell: this.renderStatus,
					},
				]}
				filterable
                paginationApi={this.pagination}
                filtrationApi={this.filtration}
                isSinglePage
                fullPage
			/>
        );
	}
	
	renderPendingUsers() {
		const { pendingUsers = [] } = this.state;

        return (
            <Table
				title={{
					icon: "user2",
					title: Strings.sidebar.pendingUsers
				}}
				data={pendingUsers}
				columns={[
					{
						Header: Strings.fields.name,
						id: 'name',
						accessor: (row: any) => row.name,
					},
					{
						Header: Strings.fields.email,
						id: 'email',
						accessor: (row: any) => row.email,
					},
					{
						Header: Strings.users.associateNumber,
						id: 'asbhipNumber',
						accessor: (row: any) => row.asbhipNumber,
					},
					{
						Header: Strings.staff.status,
						id: "asbhipNumberConfirmed",
						accessor: (row: any) => (row),
						Cell: this.renderStatus,
					},
				]}
				fullPage
				filterable
				paginationApi={this.pagination}
				filtrationApi={this.filtration}
				actions={{
					toggle: (original: any) => ({
						value: original.asbhipNumberConfirmed,
						onChange: () => {this.acceptAssociate(original)}
					})
				}}
			/>
        );
	}

	render() {
        const { activeTab } = this.state;
        return (
            <div className="ScreenUsers">
                <Helmet>
                    <title>{Strings.sidebar.users}</title>
                    <meta name="description" content="Description of Users" />
                </Helmet>
                <ContentWrapper extraStyle={{ padding: 20 }}>
                    <Tabs
                        defaultActiveKey="users"
                        onChange={(key: string) =>
                            this.setState(
                                {
                                    activeTab: key,
                                    page: 0,
                                    pageSize: 100,
                                    total: 0,
                                    columnSearch: null,
                                    globalSearch: "",
                                },
                                () => this.getData()
                            )
                        }
                    >
                        <Tabs.TabPane
                            tab={
                                <div className="TabIconContainer">
                                    <Icon name="user" />
                                    <p style={{ margin: 0 }}>{Strings.sidebar.users}</p>
                                </div>
                            }
                            key="users"
                        />
                        <Tabs.TabPane
                            tab={
                                <div className="TabIconContainer">
                                    <Icon name="user" />
                                    <p style={{ margin: 0 }}>{Strings.sidebar.pendingUsers}</p>
                                </div>
                            }
                            key="pendingUsers"
                        />
                    </Tabs>
                    <div className="UsersTableContainer">
                        {activeTab === "users" && this.renderUsers()}
                        {activeTab === "pendingUsers" && this.renderPendingUsers()}
                    </div>
                </ContentWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(Users);