/*
 *
 * Events
 * 
 */

import React from 'react';
import { connect } from 'react-redux';
import { setLoader, setTitle, delayedDispatch, setBreadcrumb, 
	updateCrumb 
} from 'store/actions';
import { Table, Icon } from 'components';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet';
import { push } from 'connected-react-router';
import Strings from 'utils/strings';
import { toast, translate } from 'utils/utils';
import './styles.scss';
import { API, Endpoints } from 'utils/api';
import {
    Col,
    notification,
    Row,
    Tooltip,
    Modal
} from "antd";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";

//import { Props, State } from './types';

export class Events extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			events: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(''));
        dispatch(setBreadcrumb(null));

		delayedDispatch(
            setBreadcrumb(() => {
                return {
                    locations: [
                        {
                          text: Strings.sidebar.events,
                          icon: "file",
                        },
					],
                }
            })
		);

        //this.getData();
		this.getEvents();
	}

	async getData(pause?: boolean) {
        const { page, pageSize, columnSearch, globalSearch } = this.state;
		const { dispatch } = this.props;

        setTimeout(async () => {
			if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;
			dispatch(setLoader(true));

            const body = {
                filters: columnSearch ? Object.keys(columnSearch).map(elem => (
                    columnSearch[elem] !== '' ? {
                            field: elem,
                            query: columnSearch[elem],
                        } : {}
                )) : [],
                page: page,
                perPage: pageSize,
                search: globalSearch,
            };

            try {
				const response = await API.post({
					url: Endpoints.uriEvents('search'),
					data: body,
				});

				if (response.ok) {
					const { users = [], total = 0 } = response.data.results;
					this.setState({ users, total });
				}
			} catch (err) {
                notification.error({
                    message: Strings.serverErrors.title,
                    description: err as string,
                    placement: 'bottomRight',
                    duration: 5,
                });
			}

            dispatch(setLoader(false));
        }, pause ? 1000 : 0)
    }

	componentDidUpdate() {
		const { dispatch } = this.props;
        dispatch(updateCrumb());
	}

	async getEvents() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriEvents(),
				data: {},
			});
	
			if (response.ok) {
				this.setState({ events: response.data.results.event || [] });
				//console.log(response);
			} else {
				toast.error(response.data?.message || Strings.serverErrors.title);
			}
		} catch (error) {
			toast.error(error as string)
		}
		dispatch(setLoader(false));
		
		
	}

    onDelete = async (id: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		await API.delete({ url: Endpoints.uriEvents(id) }).then(response => {
			if (response.ok && response.data.results.event) {
				this.setState({ events: response.data.results.event });
			} else
				toast.error(response.data.message);
		}).catch(err => {
			toast.error(err);
		});

		dispatch(setLoader(false));
	}

	getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

	onDrop(files: any) {
        try {
            const file = files[files.length - 1];

            new Compressor(file, {
                quality: 0.9,
                maxWidth: 400,
                mimeType: "image/jpeg",
                success: (result: any) => {
                    this.getBase64(result).then((res) => {
						this.setState({
							image: { file: result, preview: res },
							hasUnsavedFields: true,
						});
                    });
                },
            });
        } catch (err) {
            console.log('err', err)
            notification.warn({
                message: Strings.errors.unsupportedFile,
                description: Strings.errors.fileNotSupported,
                placement: 'bottomRight',
                duration: 5,
            });
        }
    }

	renderImageEvent() {
        const {
            image,
        } = this.state;

        return (
            <React.Fragment>
                <div style={{ marginTop: 10 }} className="GenericTitleContainer">
                    <Icon name="shop" />
                    <h1>{Strings.events.title}</h1>
                </div>
                <Row gutter={[20, 10]} style={{ marginBottom: 10 }}>
                    <Col xs={32} md={16}>
                        <label
                            htmlFor="image_upload"
                            className="InputLabel"
                        >
                            {Strings.fields.image}
                        </label>
                        <div className="EventMainImage">
                            <Dropzone
                                accept="image/jpg, image/jpeg, image/png"
                                className="GenericImageUpload"
                                id="image_upload"
                                onDrop={(files: any) => this.onDrop(files)}
                            >
                                {image ? (
                                    <div
                                        className="EventImage"
                                        style={{ backgroundImage: (image && `url('${image?.preview || image}')`) || 'none' }}
                                    />
                                ) : (
                                    <div className={`GenericImageUploadOverlay${!image ? ' --visible' : ''}`}>
                                        <Icon name="frame" />
                                        <span>{Strings.generic.changeImage}</span>
                                    </div>
                                )}
                                {image && (
                                    <button
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            e.stopPropagation();
                                            this.setState({ image: null, hasUnsavedFields: true });
                                        }}
                                        className="GenericImageDelete"
                                    >
                                        <Icon name="close" />
                                    </button>
                                )}
                            </Dropzone>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    confirmSent = async (event: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		await API.patch({
			url: Endpoints.uriEvents(`${event._id}/sent`),
		}).then(response => {
			if (response.ok) {
				this.setState({ openConfirmSentModal: false, surveys: response.data.results.event });
				toast.success(response.data.message);
			} else
				toast.error(response.data.message);
		}).catch(err => {
			toast.error(err);
		});

		dispatch(setLoader(false));
	}

    confirmExpire = async (event: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		await API.patch({
			url: Endpoints.uriEvents(`${event._id}/expire`),
		}).then(response => {
			if (response.ok) {
				this.setState({ openConfirmSentModal: false, events: response.data.results.event });
				toast.success(response.data.message);
			} else
				toast.error(response.data.message);
		}).catch(err => {
			toast.error(err);
		});

		dispatch(setLoader(false));
	}

    renderConfirmEvent = () => {
		const { openConfirmSentModal } = this.state;
		//const { language } = this.props;

		//console.log('OPEN CONFIRM MODAL', openConfirmSentModal);

		return (
			<Modal
				centered
				className="resendInvite"
				style={{ textAlign: 'center' }}
				visible={openConfirmSentModal}
				cancelText={Strings.generic.close}
				okText={Strings.events.confirm}
				onOk={() => {
					openConfirmSentModal?.sent ? this.confirmExpire(openConfirmSentModal) : this.confirmSent(openConfirmSentModal)
				} }
				onCancel={() => this.setState({ openConfirmSentModal: false })}
				title={null}
				closable={false}
				bodyStyle={{ minHeight: 420 }}
			>
				<Icon name={ openConfirmSentModal?.sent ? 'close1' : 'tarefa'} style={{ fontSize: '50px' }}></Icon>
				<div className="title">
					{
						openConfirmSentModal?.sent ? Strings.formatString(Strings.events?.sendExpire) :
						translate(openConfirmSentModal?.name) ? Strings.formatString(Strings.events?.sendConfirmation, translate(openConfirmSentModal?.name)) :
						Strings.events?.sendConfirmation
					}	
				</div>
			</Modal>
		);
	}

    getStatus(status: any) {
		if (status) {
			return Strings.events.sent;
		}
		return Strings.events.send;
	}

	validEvent() {
        const { tempEvent, } = this.state;

        if (!tempEvent?.name?.trim()) {
            notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.events.invalidName,
                placement: 'bottomRight',
                duration: 5,
            });

            return false;
        }

		if (!tempEvent?.date?.trim()) {
            notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.events.invalidDate,
                placement: 'bottomRight',
                duration: 5,
            });

            return false;
        }

        if (!tempEvent?.description.trim()) {
            notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.events.invalidDescription,
                placement: 'bottomRight',
                duration: 5,
            });

            return false;
        }

        return true;
    }

    renderStatus = (elem: any) => {
		if (elem.value === 'true')
			return (
				<div className="status __confirmed">
					<span>{this.getStatus(true)}</span>
				</div>
			);

		return (
			<Tooltip title={Strings.events.send}>
				<div
					className="status __pending"
					onClick={() => this.setState({ openConfirmSentModal: elem.cell.row.original })}>
					<Icon name="tarefa" className="resend" />
					<span>{this.getStatus(false)}</span>
				</div>
			</Tooltip>
		);
	}

    getExpireStatus(status: any) {
		if (status === 'expired') {
			return Strings.events.expired;
		}
		return Strings.events.toExpire;
	}

    renderExpire = (elem: any) => {
		const isExpired = Number(DateTime.fromISO(elem.value.dueDate).diff(DateTime.utc(), 'seconds').toObject().seconds) < 0;
		let isExpire;
		if (!elem.value.expired && elem.value.sent && !isExpired) {
			isExpire = 'toExpire'
		} else if (!elem.value.expired  && elem.value.sent && isExpired) {
			isExpire = 'expired'
		} else if (elem.value.expired) {
			isExpire = 'expired'
		} else isExpire = 'NA'

		if (isExpire === 'expired')
			return (
				<div className="expire __expired">
					<span>{this.getExpireStatus(isExpire)}</span>
				</div>
			);
		
		if (isExpire === 'NA')
			return (
				<div></div>
			);
		
		return (
			<Tooltip title={Strings.events.toExpire}>
				<div
					className="expire __toExpire"
					onClick={() => this.setState({ openConfirmSentModal: elem.cell.row.original })}>
					<Icon name="close1" className="resend" />
					<span>{this.getExpireStatus(isExpire)}</span>
				</div>
			</Tooltip>
		);
	}

	render() {
		const { events = [] } = this.state;
        
		const { dispatch } = this.props;
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.events}</title>
					<meta name="description" content="Description of Events" />
				</Helmet>
				<Table
					title={{
						icon: "user2",
						title: Strings.sidebar.events
					}}
					data={events}
					columns={[
                        {
							Header: Strings.events.image,
							id: 'image',
							accessor: (row: any) => row.image,
							type: "image",
							maxWidth: 65,
						},
						{
							Header: Strings.events.title,
							/*id: 'title',
							accessor: (row: any) => translate(row.title),*/
							id: 'name',
							accessor: (row: any) => translate(row.name) || '-',
						},
						{
							Header: Strings.events.details,
							/*id: 'details',
							accessor: (row: any) => translate(row.details),*/
							id: 'description',
							accessor: (row: any) => translate(row.description) || '-',
						},
                        {
							Header: Strings.events.dateevent,
							id: 'date',
							accessor: (row: any) => DateTime.fromISO(row.date).toFormat('dd-MM-yyyy') || '-',
						},
					]}
					fullPage
					isSinglePage
                    filterable
					add={{
						label: String(Strings.formatString(Strings.generic.addNewM, Strings.sidebar.event.toLowerCase())),
						onClick: () => dispatch(push('/events/new')),
					}}
					actions={{
                        edit: (original, value) => ({
                            onClick: () => dispatch(push(`/events/${original._id}`)),
                        }),
                        remove: (original, value) => ({
                            onClick: () => this.onDelete(original._id),
                        }),
                    }}
				/>
                {this.renderConfirmEvent()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Events);