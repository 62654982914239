/*
*
* EventDetail
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setLoader, setBreadcrumb, delayedDispatch, updateCrumb } from 'store/actions';
import { Helmet } from 'react-helmet';
import { Row, Col, Input, DatePicker, notification } from "antd";
import { API, Endpoints } from "utils/api";
import { 
	ContentWrapper, 
	//Table, EditSidebar, 
	Icon } from "components";
//import { push } from 'connected-react-router';
import { toast } from 'utils/utils';
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import Strings from 'utils/strings';
import moment from 'moment';

import { Props } from "./types";

import './styles.scss';

//const { Option } = Select;

export class EventDetail extends React.Component<Props, any> {
	constructor(props: Props) {
		super(props);

		this.state = {
			language: 'pt',
			event: null,
			image: null,
			name: null,
			description: null,
			date: null,
			notificationTitle: null,
			notificationDescription: null,
			scheduleDate: null,
			days: 0,
			sent: true,
            hasUnsavedFields: false,
		};
	}

	componentDidMount() {
		const { match, dispatch } = this.props;

		// change this
		dispatch(setTitle('EventDetail'));

		delayedDispatch(
			setBreadcrumb(() => {
				const { language } = this.state;
				const { match } = this.props;

				return {
					locations: [
						{
						  text: Strings.sidebar.events,
						  route: "/events",
						  icon: "file",
						},
						{
							text: match.params.id === 'new' ? 'new' : this.state.event?.title?.[this.state.language],
							icon: match.params.id === 'new' ? 'adicionar-acrescentar' : 'lapis',
						},
					],
					actions: [
						{
							type: "language",
							value: language,
							onChange: (lang: any) => this.setState({ language: lang }),
							className: "BreadcrumbLanguage",
							margin: "left",
						},
						{
							type: "button",
							text: Strings.generic.save,
							onClick: this.handleSubmit,
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							separator: 'left',
							isSave: true,
						},
					]
				}
			})
		);

		if (match.params.id !== 'new') this.getEvent();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getEvent() {
		const { match } = this.props;

		const response = await API.get({
			url: Endpoints.uriEvents(match.params.id),
		});
	  
		if (response.ok && response.data.results) {
			const { event } = response.data.results || {};

			this.setState({
				event,
				...event,
			});
		} else {
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
	}

	isValid() {
		const { title, dateevent, details } = this.state;

		return title && dateevent && details;
	}

	handleSubmit = async () => {
        const { image, name, description, date, notificationTitle, notificationDescription, scheduleDate } = this.state;
        const { dispatch, match } = this.props;


		if (!name || !date || !description) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.errors.invalidFields,
                placement: 'bottomRight',
                duration: 5,
            });
        }

        //if (!this.isValid() || !selectedEvent) return;

        dispatch(setLoader(true));

        const request = match.params.id === "new" ? API.post : API.put;

		const body = new FormData();

        body.append('name', JSON.stringify(name));
        body.append('description', JSON.stringify(description));
        body.append('date', date);
        body.append('notificationTitle', JSON.stringify(notificationTitle) || '');
        body.append('notificationDescription', JSON.stringify(notificationDescription) || '');
		body.append('scheduleDate', scheduleDate);

        if (image && Object.keys(image).length > 0) {
			if (image.file) {
				body.append("image", image.file);
			} else {
				body.append("image", image);
			}
		}


        try {
            const response = await request({
				url: Endpoints.uriEvents(match?.params?.id !== 'new' ? match?.params?.id : ''),
                data: body,
            });

			//console.log(response);
            if (response?.ok) {
                const { event = {} } = response.data.results;

                this.setState({ event, ...event, hasUnsavedFields: false, sent: true });

                notification.success({
                    message: Strings.events.header,
                    description: Strings.events.addEvent,
                    placement: "bottomRight",
                    duration: 5,
                });
				/*dispatch(push(`/`))
				dispatch(push(`/events/${response.data.results.event._id}`))
			} else {
				dispatch(setLoader(false));
				return toast.error(response.data?.message || Strings.serverErrors.title);*/
			}
        } catch (err) {
            notification.error({
                message: Strings.serverErrors.title,
                description: (err as string) || Strings.serverErrors.wentWrong,
                placement: "bottomRight",
                duration: 5,
            });
        }

        dispatch(setLoader(false));
    };

	validEvent() {
        const { title, dateevent, details, } = this.state;

        if (!title) {
            notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.events.invalidName,
                placement: 'bottomRight',
                duration: 5,
            });

            return false;
        }

        if (!dateevent) {
            notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.events.invalidDate,
                placement: 'bottomRight',
                duration: 5,
            });

            return false;
        }

        if (!details) {
            notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.events.invalidDescription,
                placement: 'bottomRight',
                duration: 5,
            });

            return false;
        }

        return true;
    }	
	
	onDelete = async (id: any) => {
		const { match } = this.props;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		await API.delete({ url: Endpoints.uriEvents(`${match.params.id}/user/${id}`) }).then(response => {
			if (response.ok && response.data.results.event) {
				this.setState({ event: response.data.results.event, openSidebar: false, item: {} });
			} else
				toast.error(response.data.message);
		}).catch(err => {
			toast.error(err);
		});

		dispatch(setLoader(false));
	}

	onChange = (field: string, value: any) => {
		const { item } = this.state;

		this.setState({ item: { ...item, [field]: value } });
	}

	getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    onDrop(files: any) {
        try {
            const file = files[files.length - 1];

            new Compressor(file, {
                quality: 0.9,
                maxWidth: 400,
                mimeType: "image/jpeg",
                success: (result: any) => {
                    this.getBase64(result).then((res) => {
                        this.setState({
                            image: { file: result, preview: res },
                            hasUnsavedFields: true,
                        });
						
                    });
                },
            });
        } catch (err) {
            console.log('err', err)
            notification.warn({
                message: Strings.errors.unsupportedFile,
                description: Strings.errors.fileNotSupported,
                placement: 'bottomRight',
                duration: 5,
            });
        }
    }

	renderNotification() {
		const { notificationTitle, notificationDescription, language, scheduleDate, sent } = this.state;

		return (
			<Col xs={24}>
				<div className="GenericTitleContainer">
					<Icon name="notification" className='MainHeaderIcon' />
					<span className='MainHeaderTitle'>{Strings.notifications.title}</span>
				</div>
				<div className="NotificationDetailScreen">
				<Row gutter={[10, 10]}> 
					<Col xs={24} md={12}>
						<label className="SingleLabel">{Strings.notifications.title}</label>
						<Input
							placeholder={Strings.notifications.title}
							defaultValue={notificationTitle ? notificationTitle[language] : ''}
							value={notificationTitle && notificationTitle[language]}
							onChange={e => {
								this.setState({
									notificationTitle: { 
										...notificationTitle, 
										[language]: e.target.value 
									},
									hasUnsavedFields: true,
								});
							}}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label
							htmlFor="notification_date"
							className="SingleLabel"
						>
							{Strings.notifications.date}
						</label>
						<DatePicker
							id="notification_date"
							style={{ width: "100%" }}
							showTime
							disabled={sent}
							placeholder={Strings.notifications.date}
							value={
								scheduleDate
									? moment(scheduleDate)
									: undefined
							}
							format='DD-MM-YYYY HH:mm'
							disabledDate={(d) =>
								!d ||
								d.isBefore(
									moment.utc().add(-1, "day")
								) ||
								d.isAfter(moment.utc().add(this.daysLeft(), "day"))
							}
							onChange={(scheduleDate : any) => {
								this.setState({
									scheduleDate: scheduleDate,
									hasUnsavedFields: true,
								}, );
							}}
						/>
					</Col>
					<Col xs={24}>
						<label className="SingleLabel">{Strings.notifications.message}</label>
						<Input.TextArea
							placeholder={Strings.notifications.message}
							defaultValue={notificationDescription?.[language] || ''}
							value={notificationDescription?.[language] || ''}
							style={{ minHeight: '120px' }}
							onChange={e => {
								this.setState({
									notificationDescription: {
										...notificationDescription,
										[language]: e.target.value,
									},
									hasUnsavedFields: true,
								});
							}}
						/>
					</Col>
				</Row>
				</div>
			</Col>
		)
	}

	daysLeft() {
		let {date} = this.state;
		//console.log(scheduleDate);
		//console.log(date);
		let currentDate = new Date();
		let cDay = currentDate.getDate();
		let cMonth = currentDate.getMonth() + 1;
		let cYear = currentDate.getFullYear();
		let cHour = currentDate.getHours();
		let cMinutes = currentDate.getMinutes();
		let cSeconds = currentDate.getSeconds();
		let Datetime = moment(cYear + "-" + cMonth + "-" + cDay + " " + cHour + ":" + cMinutes + ":" + cSeconds);
		let DatePick = moment(date);
		var amount = DatePick.diff(Datetime, 'days');
		return amount + 1;

	  }
	  

	render() {
		const { language, name, description, date, image } = this.state;
		return (
			<div className="Screen-Event">
				
				<Helmet>
					<title>{Strings.sidebar.events}</title>
					<meta name="description" content="Description of Events" />
				</Helmet>
				<ContentWrapper extraStyle={{ padding: 20 }}> 
					<Row gutter={[0,10]}>
						<Col xs={24}>
							<div className="GenericTitleContainer">
								<Icon name="file" className='MainHeaderIcon' />
								<span className='MainHeaderTitle'>{Strings.generic.details}</span>
							</div>
							<Row gutter={[10, 10]}>
								<Col xs={24} md={12}>
									<label
										htmlFor="image_upload"
										className="InputLabel"
									>
										{Strings.fields.image}
									</label>
									<div className="EventMainImage" >
										<Dropzone
											accept="image/jpg, image/jpeg, image/png"
											className="GenericImageUpload"
											id="image_upload"
											onDrop={(files: any) => this.onDrop(files)}
										>
											{image ? (
												<div
													className="EventImage"
													style={{ backgroundImage: (image && `url('${image?.preview || image}')`) || 'none' }}
												/>
											) : (
												<div className={`GenericImageUploadOverlay${!image ? ' --visible' : ''}`}>
													<Icon name="frame" />
													<span>{Strings.generic.changeImage}</span>
												</div>
											)}
											{image && (
												<button
													onClick={(e: React.MouseEvent<HTMLElement>) => {
														e.stopPropagation();
														this.setState({ image: null, hasUnsavedFields: true });
													}}
													className="GenericImageDelete"
												>
													<Icon name="close" />
												</button>
											)}
										</Dropzone>
									</div>
								</Col>
								<Col xs={24} md={12}>
									<label 
										htmlFor="event_title"
										className="SingleLabel __required"
									>
										{Strings.events.title}
									</label>
									<Input
										id="event_title"
										defaultValue={name ? name[language] : ''}
										value={name && name[language]}
										//value={name || ""}
										placeholder={Strings.events.title}
										onChange={e => {
											this.setState({
												name: {
													...name,
													[language]: e.target.value
												},
												hasUnsavedFields: true,
											});
										}}
									/>

									<label
										htmlFor="event_dateevent"
										className="SingleLabel __required"
									>
										{Strings.fields.date}
									</label>
									<DatePicker
										id="event_dateevent"
										value={date ? moment(date) : null}
										disabledDate={(d) =>
											!d ||
											d.isBefore(
												moment.utc().add(-1, "day")
											) ||
											d.isAfter(moment.utc().add(1, "year"))
										}
										format='DD-MM-YYYY HH:mm'
										style={{ width: '100%' }}
										showTime
										placeholder={Strings.events.dateevent}
										onChange={(date: any) => {
											this.setState({ hasUnsavedFields: true, date: date, sent: false });
										}}
									/>
								</Col>
							
								<Col xs={24}>
									<label
										htmlFor="event_details"
										className="SingleLabel __required"
									>
										{Strings.events.details}
									</label>
									<Input.TextArea
										id="event_details"
										placeholder={Strings.events.details}
										value={description ? description[language] : ''}
										style={{ minHeight: '120px' }}
										onChange={(e: any) =>
											this.setState({
												description: {
													...description,
													[language]: e.target.value,
												},
												hasUnsavedFields: true,
											})
										}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</ContentWrapper>

				<ContentWrapper extraStyle={{ padding: 20 }}>
					{this.renderNotification()}
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	user: state.user,
});

export default connect(mapStateToProps)(EventDetail);