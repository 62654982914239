import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
	/* cSpell:enable */
	/* cSpell.language:en */
	en: {
		// Generic
		generic: {
			edit: 'Edit',
			done: 'Done',
			accept: 'Accept',
			cancel: 'Cancel',
			remove: 'Remove',
			delete: 'Delete',
			success: 'Success',
			active: 'Active',
            forget: 'Forget User',
			expand: 'Expand',
			toggle: 'Toggle',
			save: 'Save',
			yes: 'Yes',
			no: 'No',
			apply: 'Apply',
			item: 'Item',
			items: 'Items',
			submit: 'Submit',
			continue: 'Continue',
			confirm: 'Confirm',
			finish: 'Finish',
			back: 'Back',
			oops: 'Oops',
			error: 'Error',
			retry: 'Retry',
			reset: 'Reset',
			search: 'Search',
			noResults: 'No Results',
			clickHereTo: 'Click here to {0}',
			requiredField: '{0} is a required field',
			duplicateEmail: 'This email is already taken. Please use another email.',
			emptyList: 'No {0} in List',
			leavePage: 'Leave Page?',
			leave: 'Leave',
			stay: 'Stay',
			open: 'Open',
			close: 'Close',
			change: 'Change',
			free: 'Free',
			send: 'Send',
			selected: 'Selected',
			selecteds: 'Selecteds',
			swalEditTitle: 'Cancel Edit?',
			swalEditDescription: 'Cancelling edit will erase all the unsaved information!',
			leaveDescription: 'Leaving this page will erase all the unsaved information!',
			swalDeleteTitle: 'Delete {0}?',
			swalDeleteDescription: "By leaving this page, you'll erase all the {0} on your list",
			genericSelect: 'Select {0}',
			select: 'Select',
			addGeneric: 'Add {0}',
			moreOptions: 'More Options',
			all: 'All',
			enable: 'Enable',
			newF: 'New',
			newM: 'New',
			addNewF: 'Add new {0}',
			addNewM: 'Add new {0}',
			default: 'Default',
			editGeneric: 'Edit {0}',
			infinite: 'Infinite',
			details: 'Details',
			noItemsSelected: 'No Items Selected',
			clearList: 'Clear List',
			loading: 'Loading...',
			view: 'View',
			actions: 'Actions',
			invalidUrl: 'Invalid URL. Please remove HTTP prefix.',
			shop: 'Shop',
			noEntries: 'No Entries',
			chatWalktroughText1:
				"We've added several FAQs that will help you know and understand how the Shop2Grow product works. These FAQs are available from the chat icon at the bottom right of the screen.",
			chatWalktroughText2:
				'We are also available through chat to help you the best way on to setting up your store.',
			skip: 'Skip',
			walkTrough: 'walkthrough',
			clone: 'Clone',
			download: 'Download',
			upload: 'Upload',
			template: 'Download Template',
			export: 'Export',
			import: 'Import',
			defaultItem: 'an item',
			defaultItemRecipient: 'this {0}',
			demo: 'Demo',
			previewOnly: 'Preview Only',
			selectPaymentMethod: 'Please select a payment method before updating your subscription.',
			basic: 'Basic',
			advanced: 'Advanced',
			editMode: 'Edit Mode',
			print: 'Print',
			code: 'Code',
            changeImage: 'Click to change image or try to drag one into this container.',
            clickOrDrag: 'Click or drag file to this area',
            userImage: `This user don't have any image`,
			values: "Values",
			addNew: "Add {0}",
		},

		notifications: {
			new: 'New Notification',
			title: 'Notification Title',
			message: 'Notification Text',
			email: 'Email Content',
			date: 'Notification Scheduled Date',
			sendEmail: 'Send E-mail',
			created: 'Notification has been created successfully.',
			edited: 'Notification has been edited successfully.',
			deleted: 'Notification has been deleted successfully.',
			missingTitle: 'Title is a mandatory field.',
			missingDescription: 'Description is a mandatory field.',
			missingDate: 'Scheduled Date is a mandatory field.',
			missingEmailContent: 'Email content is a mandatory field.',
		},


		fields: {
			name: 'Name',
			address: 'Address',
			addresses: 'Addresses',
			country: 'Country',
			state: 'State',
			city: 'City',
			zipCode: 'Zip Code',
			description: 'Description',
			phone: 'Phone',
			phoneNumber: 'Phone Number',
			phoneShort: 'Tel.:',
			mobilePhone: 'Mobile Number',
			email: 'Email',
			birthdate: 'BirthDate',
			groupAge: 'Age Group',
			groupIncome: 'Income Group',
			groupRegion: 'Region',
			seoUrl: 'SEO Url',
			tags: 'Tags',
			ccNumber: 'CC Number',
			/*billingAddress: 'Billing Address',
			shippingAddress: 'Shipping Address',
			vat: 'VAT',*/
			password: 'Password',
			message: 'Message',
			image: 'Image',
			images: 'Images',
			imgPlaceholder: 'Try dropping some images here, or click to select files to upload',
			filesPlaceholder: 'Try dropping your file here, or click to select a file',
			subTotal: 'Subtotal',
			total: 'Total',
			/*quantity: 'Quantity',
			ordered: 'Ordered',
			totalQuantity: 'Total Quantity',
			discount: 'Discount',*/
			registerDate: 'Register Date',
			invalidEmail: 'Email is not Valid',
			//tax: 'Tax',
			addedOn: 'Added On',
			startDate: 'Start Date',
			endDate: 'End Date',
			startHour: 'Start Hour',
			endHour: 'End Hour',
			filename: 'Filename',
			gender: 'Gender',
			waitingForResults: 'Waiting for Name or SKU...',
			link: 'Link',
			date: 'Date',
			/*otherRegions: 'Other Zones',
			taxValues: 'Tax Values',*/
			previewLink: 'Preview Link',
			clickToUpload: 'Click to Upload',
			photo: 'Photo',

			// Select
			selectCountry: 'Select Country',
			selectState: 'Select State',
			selectFilters: 'Select Filters',
			selectDiscount: 'Select Discount',

			countries: 'Countries',
			states: 'States',

			// Pairs
			valuePair: '{0} / {1}',
			placeHolderPair: '{0} => {1}',

			code: 'Abbreviation',
			bccEmail: 'Email to receive orders',

			inviteCode: 'Code',
			cohort: 'Cohort',
			logo: 'Logo',
		},

		language: {
			header: 'Language',
			defaultLanguage: 'Default Language',
			acronym: 'en',
			languageNotActive: 'Save the changes before changing the default language.',
			portuguese: 'Portuguese',
			english: 'English',
		},

		sidebar: {
			dashboard: 'Dashboard',
			profile: 'Profile',
			information: 'Information',
			staff: 'Staff',
			settings: 'Settings',
			logs: 'Logs',
			users: 'Users',
			pendingUsers: 'Pending Associates',
			events: 'Events',
			event:'Event',
			inviteCodes: 'Users Invited',
			notifications: 'Notifications',
			pendingInvite: 'Users Pending'
			//earnings: 'Earnings',
		},

		header: {
			daysLeft: 'days left on trial',
			upgrade: 'Upgrade',
			account: 'Account',
			maintenance: 'Maintenance',
			visitStore: 'Visit Store',
			deleteSuccess: 'Dummy data deleted successfully',
			extend: 'Extend Trial',
		},

		// Screens
		authentication: {
			welcome: 'Welcome',
			header: 'Authentication',
			register: 'Register',
			login: 'Login',
			logout: 'Logout',
			signIn: 'Sign in',
			confirmAccount: 'Confirm Account',
			name: 'Name',
			namePlaceholder: 'Your name',
			email: 'Email',
			emailPlaceholder: 'Your email',
			password: 'Password',
			passwordPlaceholder: 'Your password',
			newPassword: 'New password',
			newPasswordPlaceholder: 'Enter new password',
			confirmPassword: 'Confirm Password',
			confirmPasswordPlaceholder: 'Repeat your password',
			forgotPassword: 'Forgot your password? Click here.',
			loginFooter: 'No account? Register here',
			registerFooter: 'Do you have an account? Login here',
			resendCode1: 'Have you not received your confirmation code? ',
			resendCode2: 'Resend activation code',
			sendEmail: 'Send Email',
			recoverPassword: 'Recover Password',
			changePassword: 'Change Password',
			changeEmail: 'Change Email',
			newEmail: 'New Email',
			repeatEmail: 'Repeat Email',
			newEmailConfirm: 'Confirm New Email',
			currentEmail: 'Current Email',
			currentPassword: 'Current Password',
			emailSent: 'Email Sent Successfully',
			repeatPassword: 'Repeat Password',
			rememberedPassword: 'Did you remember your password?',
			adminPassword: 'Admin Password',
			notLoggedIn: 'You need to be logged in to access this page',
			noMatchPassword: 'Passwords don\'t match',
			acceptSubtitle: 'Please input a password to confirm your account.',
            confirmInvite: 'Please type your password to accept invite.',
			goBackToLogin: 'Go to the login screen',
			inviteUser: 'Invite User',
			resendInviteEmail: 'Resend Invitation Email',
			confirmResendInviteEmail: 'Resend an invitation email to {0}?',
			confirmInviteUser: 'Are you sure that you want to invite {0}?',
			forgetTitle: 'Are you sure you want to forget the user {0}? This action will leave the user without access to the app and all the user information will be removed.',
            forgetConfirm: 'Forget User',
		},

		acceptInvite: {
            header: 'Accept Invite',
            title: 'Hi {0}, {1} has invited you to belong {2}',
            title2: 'Hi {0}, {1} has invited you to manage {2}',
            subTitle: 'Please choose a password to confirm your account',
            expired: 'This invitation link has expired',
        },

		notFound: {
			header: 'Not Found',
		},

		importModal: {
			fileName: 'Filename',
			size: 'Size',
			import: 'Import',
        },
        
        errors: {
			invalidArgs: 'Invalid or missing arguments',
			invalidFields: 'Invalid or missing fields',
			emailIsInvalid: 'Email format is not valid',
			fillEmail: 'Please input your email',
			fillPassword: 'Please input your password',
			pleaseFillFormCorrectly: 'Please fill the form correctly',
			fillField: 'This field is mandatory',
			notSupportedFile: 'File type not supported',
			nameIsEmpty: 'Please fill name field',
			passwordsDontMatch: 'Fields \'New password\' and \'Confirm password\' don\'t match',
			emailIsEmpty: 'Please fill email field',
			fileTooBigorInvalid: 'File size is larger than 8MB or file is corrupt',
			unsupportedFile: 'File not supported',
            fileNotSupported: 'File you tried to upload is not supported.',
            processingError: 'Processing Error',
            imageProcessingError: '{images} could not be processed.',
		},

		profile: {
			profilePicture: 'Profile Picture',
			role: 'Role',
			editedSuccessfully: 'Profile edited successfully',
		},

		placeholders: {
			name: 'Your name',
			email: 'your@email.com',
			phoneNumber: '+351265000000',
			language: 'Select a language option',
			role: 'Select a role',
			currentPassword: 'actual password',
			newPassword: 'new password',
			confirmPassword: 'new password',
			newEmail: 'new@email.com',
			page: 'page',
			titlePage: 'Page Title',
			link: 'https://www.google.com/',
		},

		staff: {
            owner: 'Owner',
            owners: 'Owners',
            admin: 'Administrator',
            editedSuccessfully: 'Staff edited successfully',
            passwordChangedSuccessfully: 'Password changed successfully',
            emailChangedSuccessfully: 'Email changed successfully',
            wrongPassword: 'Wrong password',
            emailInUse: 'Email in use',
            status: 'Status',
            confirmed: 'Confirmed',
            pending: 'Pending',
			invite: 'Invite',
            createStaff: 'Create Staff',
            createdSuccessfully: 'Staff member has been created successfully.',
            sysAdmin: 'System Admin',
            addStaff: 'Add a Staff Member',
            editStaff: 'Edit Staff Member',
            addOwner: 'Add an Owner',
            editOwner: 'Edit Owner',
            user: 'User',
            removeStaff: 'Remove Staff',
            removed: 'User has been removed as Staff successfully.',
            disableStaff: 'Disable Staff Member',
            enableStaff: 'Enable Staff Member',
            disabled: 'Staff member disabled successfully.',
            enabled: 'Staff member enabled successfully.',
        },

		ranges: {
			today: 'Today',
			thisMonth: 'This month',
			lastMonth: 'Last month',
			thisYear: 'This year',
			lastYear: 'Last year',
		},

		dashboard: {
            period: 'Period',
            active: 'Active',
            homepage: 'Homepage',
            inStock: 'In Stock',
            daily: 'Dialy',
            monthly: 'Monthly',
            newUsers: 'New Users',
            newUsersContent: 'New Users Content',
            uses: 'Uses',
            androidUse: 'Android Usage',
            androidUseContent: 'Android Usage Content',
            iOSUse: 'iOS Usage',
            iOSUseContent: 'iOS Usage Content',
            webUse: 'Web Usage',
            webUseContent: 'Web Usage Content',
        },

		settings: {
			pages: 'Pages',
			emailTemplates: 'E-mail Settings',
			pagesSubTitle:
                "In this area you will be able to change the content of pages, like Terms & Condition and Privacy Policy",
            emailsSubTitle:
                "Setup email templated, used to sent emails to users, and also used to manage staff invitation",
		},

		pages: {
			single: 'Page',
			title: 'Title',
			content: 'Content',
			new: 'New Page',
			isLink: 'Redirect',
		},

		templates: {
			single: 'Template',
		},

		emails: {
            single: 'E-mail',
            tags: 'Tags',
            preview: 'Preview',
            subject: 'Subject',
            cc: 'CC',
            templates: 'E-mail Templates',
            edited: 'E-mail template gravado com sucesso.',
        },

		serverErrors: {
            title: 'Internal Server Error',
            subTitle: 'It\'s not you, it\'s us!',
            refresh: 'Go Back to Previous Page',
            backToDashboard: 'Go to Dashboard',
            wentWrong: 'Something went wrong.',
        },

		logs: {
			dateRange: 'Date Range',
			userId: 'User Id',
			url: 'Url',
			filters: 'Filters',
			token: 'Token',
			codes: 'Codes',
			methods: 'Methods',
			sources: 'Sources',
			types: 'Types',
		},

		events: {
			title: 'Name',
			header: 'Event',
			image: 'Image',
			details: 'Details',
			imagedetail: 'Image Details',
			dateevent: 'Date Event',
			send: "Send",
			sent: "Sent",
			sendConfirmation: 'Are you sure that you want to send the event to the users?',
			expired: 'Expired',
			toExpire: 'Expire',
			link: "Typeform Link",
			confirm: 'Confirm',
			sendExpire: 'Are you sure that you want to expire the event to the users?',
			addEvent: 'Add an Event',
			editEvent: 'Edit an Event',
            invalidName: 'Name is a mandatory field.',
			invalidDate: 'Date is a mandatory field.',
			invalidDescription: 'Description is a mandatory field.',
		},

		users: {
            header: 'User',
            fullInfo: 'User\'s Information',
			associateNumber: 'Associate Nr.',
        },

		emailTemplates: {
			generalEmail: 'Generic E-mail',
			accountConfirmationCode: 'User Account Confirmation by Code',
			recoverPasswordCode: 'User Password Recovery by Code',
			accountConfirmationLink: 'User Account Confirmation by Link',
			recoverPasswordLink: 'User Password Recovery by Link',
			reservationEmail: 'User Reservation E-mail',
			staffAccountConfirmation: 'Staff Account Confirmation for BO',
			staffRecoverPassword: 'Staff Recover Password for BO',
		},
	},
	/* cSpell.language:pt,pt_PT */
	pt: {
		// Generic
		generic: {
			edit: 'Editar',
			done: 'Concluir',
			accept: 'Aceitar',
			cancel: 'Cancelar',
			remove: 'Remover',
			delete: 'Eliminar',
			success: 'Sucesso',
			active: 'Ativar',
            forget: 'Esquecer Utilizador',
			expand: 'Expandir',
			toggle: 'Toggle',
			save: 'Guardar',
			yes: 'Sim',
			no: 'Não',
			apply: 'Aplicar',
			item: 'Artigo',
			items: 'Artigos',
			submit: 'Submeter',
			continue: 'Continuar',
			confirm: 'Confirmar',
			finish: 'Terminar',
			back: 'Voltar',
			oops: 'Oops',
			error: 'Erro',
			retry: 'Tentar novamente',
			reset: 'Reset',
			search: 'Pesquisar',
			noResults: 'Sem Resultados',
			clickHereTo: 'Clique para {0}',
			requiredField: '{0} é um campo obrigatório',
			duplicateEmail: 'Este email já está a ser utilizado. Por favor utilize outro email.',
			emptyList: 'Sem {0} na lista',
			leavePage: 'Sair da Página?',
			leave: 'Sair',
			stay: 'Ficar',
			open: 'Abrir',
			close: 'Fechar',
			change: 'Mudar',
			free: 'Grátis',
			send: 'Enviar',
			selected: 'Selecionado',
			selecteds: 'Selecionados',
			swalEditTitle: 'Cancelar Alteração?',
			swalEditDescription: 'Cancelar edição vai apagar toda a informação não guardada',
			leaveDescription: 'Ao sair desta página vai apagar toda a informação não guardada',
			swalDeleteTitle: 'Apagar {0}?',
			swalDeleteDescription: 'Ao sair desta página vai apagar todos os {0} na sua lista',
			genericSelect: 'Selecionar {0}',
			select: 'Selecionar',
			addGeneric: 'Adicionar {0}',
			moreOptions: 'Mais Opções',
			all: 'Tudo',
			enable: 'Ativar',
			newF: 'Nova',
			newM: 'Novo',
			addNewF: 'Adicionar nova {0}',
			addNewM: 'Adicionar novo {0}',
			default: 'Pré-Definido',
			editGeneric: 'Editar {0}',
			infinite: 'Infinito',
			details: 'Detalhes',
			noItemsSelected: 'Nenhum Item Selecionado',
			clearList: 'Limpar lista',
			loading: 'A Carregar...',
			view: 'Ver',
			actions: 'Ações',
			invalidUrl: 'URL inválido. Por favor remova o prefixo HTTP.',
			shop: 'Loja',
			noEntries: 'Sem Valores',
			chatWalktroughText1:
				'Adicionámos diversas FAQ que podem ajudar a conhecer e entender como funciona o produto Shop2Grow. Essas FAQ estão disponíveis no ícone de chat no canto inferior direito do ecrã.',
			chatWalktroughText2:
				'Estamos também disponíveis, através de chat, para ajudar na melhor maneira de criar e definir a sua loja.',
			skip: 'Saltar Walkthrough',
			walkTrough: 'Walkthrough',
			clone: 'Clonar',
			download: 'Download',
			upload: 'Upload',
			template: 'Download Template',
			export: 'Exportar',
			import: 'Importar',
			defaultItem: 'um item',
			defaultItemRecipient: 'este/esta {0}',
			demo: 'Demo',
			previewOnly: 'Apenas Preview',
			selectPaymentMethod: 'Por favor, selecione um método de pagamento antes de atualizar a sua subscrição.',
			basic: 'Básico',
			advanced: 'Avançado',
			editMode: 'Modo de Edição',
			print: 'Imprimir',
			code: 'Codigo',
			changeImage: 'Clique para mudar a imagem ou tente arrastar uma para esta superfície.',
            clickOrDrag: 'Clique ou arraste ficheiros para esta área',
            userImage: `Este utilizador ainda não tem imagem`,
			values: "Valores",
			addNew: "Adicionar {0}",
		},

		fields: {
			name: 'Nome',
			address: 'Morada',
			addresses: 'Moradas',
			country: 'País',
			state: 'Estado',
			city: 'Cidade',
			zipCode: 'Código Postal',
			description: 'Descrição',
			phone: 'Telefone',
			phoneNumber: 'Número de Telefone',
			phoneShort: 'Tel.:',
			mobilePhone: 'Telemóvel',
			email: 'Email',
			birthdate: 'Data de nascimento',
			groupAge: 'Faixa etária',
			groupIncome: 'Grupo de Renda',
			groupRegion: 'Região',
			seoUrl: 'SEO Url',
			tags: 'Tags',
			ccNumber: 'Número do CC',
			/*billingAddress: 'Morada de Faturação',
			shippingAddress: 'Morada de Envio',
			vat: 'VAT',*/
			password: 'Palavra-Passe',
			message: 'Mensagem',
			image: 'Imagem',
			images: 'Imagens',
			imgPlaceholder: 'Tente arrastar imagens para aqui, ou clique para fazer upload',
			filesPlaceholder: 'Tente arrastar o ficheiro para aqui ou clique para selecionar o ficheiro',
			subTotal: 'Subtotal',
			total: 'Total',
			/*quantity: 'Quantidade',
			ordered: 'Qtd. Encomendada',
			totalQuantity: 'Quantidade Total',
			discount: 'Desconto',*/
			registerDate: 'Data Registo',
			invalidEmail: 'Email não é valido',
			//tax: 'Taxa',
			addedOn: 'Adicionado',
			startDate: 'Data Inicial',
			endDate: 'Data Final',
			startHour: 'Hora Inicial',
			endHour: 'Hora Final',
			filename: 'Nome do Ficheiro',
			gender: 'Género',
			waitingForResults: 'À espera do nome ou SKU...',
			link: 'Link',
			date: 'Data',
			/*otherRegions: 'Outras Zonas',
			taxValues: 'Taxas',*/
			previewLink: 'Link de Pré-Visualização',
			clickToUpload: 'Clique para fazer o Upload',
			photo: 'Foto',

			// Select
			selectCountry: 'Selecionar País',
			selectState: 'Selecionar Estado',
			selectFilters: 'Selecionar Filtros',
			selectDiscount: 'Selecionar Desconto',

			countries: 'Países',
			states: 'Estados',

			// Pairs
			valuePair: '{0} / {1}',
			placeHolderPair: '{0} => {1}',

			code: 'Abreviatura',
			bccEmail: 'Email para receber encomendas',

			inviteCode: 'Código',
			cohort: 'Cohort',
			logo: 'LogoTipo',
		},

		language: {
			header: 'Idioma',
			defaultLanguage: 'Idioma Padrão',
			acronym: 'pt',
			languageNotActive: 'Guarde as atualizações antes de proceder à alteração do idioma padrão.',
			portuguese: 'Português',
			english: 'Inglês',
		},

		sidebar: {
			dashboard: 'Dashboard',
			profile: 'Perfil',
			information: 'Informação',
			staff: 'Pessoal',
			settings: 'Definições',
			logs: 'Logs',
			users: 'Utilizadores',
			pendingUsers: 'Associados Pendentes',
			events: 'Eventos',
			event:'Evento',
			inviteCodes: 'Utilizadores Convidados',
			notifications: 'Notificações',
			pendingInvite: 'Utilizadores Pendentes'
			//earnings: 'Earnings',
		},

        header: {
			daysLeft: 'dias restantes de trial',
			upgrade: 'Upgrade',
			account: 'Conta',
			maintenance: 'Manutenção',
			visitStore: 'Visitar Loja',
			deleteSuccess: 'Dados Temporários apagados com sucesso',
			extend: 'Aumentar Trial',
        },

		// Screens
		authentication: {
			welcome: 'Bem-vindo',
			header: 'Autenticação',
			register: 'Registo',
			login: 'Iniciar Sessão',
			logout: 'Terminar Sessão',
			signIn: 'Autenticar',
			confirmAccount: 'Confirmar Conta',
			name: 'Nome',
			namePlaceholder: 'O seu nome',
			email: 'Email',
			emailPlaceholder: 'O seu email',
			password: 'Palavra-Passe',
			passwordPlaceholder: 'A sua palavra-Passe',
			newPassword: 'Nova palavra-Passe',
			newPasswordPlaceholder: 'Inserir nova palavra-Passe',
			confirmPassword: 'Confirmar Palavra-Passe',
			confirmPasswordPlaceholder: 'Repetir a palavra-Passe',
			forgotPassword: 'Esqueceu-se da palavra-Passe?',
			loginFooter: 'Não tem conta? Registe-se aqui',
			registerFooter: 'Já tem conta? Autentique-se aqui.',
			resendCode1: 'Não recebeu o seu código de confirmação? ',
			resendCode2: 'Reenviar código de ativação',
			sendEmail: 'Enviar Email',
			recoverPassword: 'Recuperar palavra-Passe',
			changePassword: 'Mudar Palavra-Passe',
			changeEmail: 'Mudar Email',
			newEmail: 'Novo Email',
			newEmailConfirm: 'Confirmar Novo Email',
			repeatEmail: 'Repetir Email',
			currentEmail: 'Email Atual',
			currentPassword: 'Password Atual',
			emailSent: 'Email Enviado com Sucesso',
			repeatPassword: 'Repetir Palavra-Passe',
			rememberedPassword: 'Lembrou-se da palavra-Passe?',
			adminPassword: 'Palavra-Passe do Administrador',
			notLoggedIn: 'Necessita de estar autenticado para ter acesso a esta página',
			noMatchPassword: 'As palvras-passe não coincidem',
			acceptSubtitle: 'Por favor insira uma palavra-passe para confirmar a sua conta.',
            confirmInvite: 'Por favor insira a sua palavra-passe para aceitar o convite.',
			goBackToLogin: 'Ir para o ecrã de início de sessão',
			inviteUser: 'Convidar Utilizador',
			resendInviteEmail: 'Reenviar email de convite',
			confirmResendInviteEmail: 'Reenviar email de convite para {0}?',
			confirmInviteUser: 'Tem certeza de que deseja convidar {0}?',
            forgetTitle: 'Tem a certeza que deseja esquecer o utilizador {0}? Esta ação irá deixar o utilizar sem acesso à app, e todos os dados serão removidos.',
            forgetConfirm: 'Esquecer Utilizador',
		},

		acceptInvite: {
            header: 'Aceitar Convite',
            title: 'Olá {0}, {1} convidou-o(a) a pertencer a {2}.',
            title2: 'Olá {0}, {1} convidou-o(a) para gerir {2}.',
            subTitle: 'Insira uma password para confirmar a sua conta',
            expired: 'Este convite expirou',
        },

		notFound: {
			header: 'Não Encontrado',
		},

		importModal: {
			fileName: 'Nome do Ficheiro',
			size: 'Tamanho',
			import: 'Importar',
        },
        
        errors: {
			invalidArgs: 'Argumentos inválidos ou em falta',
			invalidFields: 'Campos inválidos ou em falta',
			emailIsInvalid: 'O formato de email inserido não é válido',
			fillEmail: 'Insira um email por favor',
			fillPassword: 'Insira uma palavra-passe por favor',
			pleaseFillFormCorrectly: 'Por favor preencha o formulário correctamente',
			fillField: 'Este campo é de preenchimento obrigatório',
			notSupportedFile: 'Tipo de ficheiro não suportado',
			nameIsEmpty: 'O campo nome é de preenchimento obrigatório',
			passwordsDontMatch: 'Os campos \'Nova palavra-passe\' e \'Confirmar palavra-passe\' não coincidem',
			emailIsEmpty: 'O campo email é de preenchimento obrigatório',
			fileTooBigorInvalid: 'O tamanho da imagem é superior a 8MB ou tem um formato inválido',
		},

		profile: {
			profilePicture: 'Foto de Perfil',
			role: 'Cargo',
			editedSuccessfully: 'Perfil editado com sucesso',
		},

		placeholders: {
			name: 'O seu nome',
			email: 'o_seu@email.com',
			phoneNumber: '+351265000000',
			language: 'Selecione uma língua',
			role: 'Selecione um cargo',
			currentPassword: 'palavra-passe actual',
			newPassword: 'nova password',
			confirmPassword: 'nova password',
			newEmail: 'novo@email.com',
			page: 'pagina',
			titlePage: 'Título da Página',
			link: 'https://www.google.com/',
		},

		staff: {
            owner: 'Proprietário',
            owners: 'Proprietários',
            admin: 'Administrator',
            editedSuccessfully: 'Pessoal editado com sucesso',
            passwordChangedSuccessfully: 'Palavra-Passe editado com sucesso',
            emailChangedSuccessfully: 'Email editado com sucesso',
            wrongPassword: 'Palavra-Passe errada',
            emailInUse: 'Email em uso',
            status: 'Estado',
            confirmed: 'Confirmado',
            pending: 'Pendente',
			invite: 'Convidar',
            createStaff: 'Criar Pessoal',
            createdSuccessfully: 'Pessoal membro foi criado com sucesso.',
            sysAdmin: 'Sistema Administrativo',
            addStaff: 'Adicionar um Membro Pessoal',
            editStaff: 'Editar Membro Pessoal',
            addOwner: 'Adicionar um Proprietário',
            editOwner: 'Editar Proprietário',
            user: 'Utilizador',
            removeStaff: 'Remover Pessoal',
            removed: 'O utilizador foi removido como Pessoal com sucesso.',
            disableStaff: 'Desativar Membro Pessoal',
            enableStaff: 'Ativar Membro Pessoal',
            disabled: 'Membro pessoal desativado com sucesso.',
            enabled: 'Membro pessoal ativado com sucesso.',
        },

		ranges: {
			today: 'Hoje',
			thisMonth: 'Este mês',
			lastMonth: 'Último mês',
			thisYear: 'Este ano',
			lastYear: 'Último ano',
		},

		dashboard: {
            period: 'Período',
            active: 'Ativo',
            homepage: 'Homepage',
            inStock: 'Em Stock',
            daily: 'Diário',
            monthly: 'Mensal',
            newUsers: 'Novos Utilizadores',
            newUsersContent: 'Novos Utilizadores Conteúdo',
            uses: 'Utilizações',
            androidUse: 'Utilização Android',
            androidUseContent: 'Utilização Android Conteúdo',
            iOSUse: 'Utilização iOS',
            iOSUseContent: 'Utilização iOS Conteúdo',
            webUse: 'Utilização Web',
            webUseContent: 'Utilização Web Conteúdo',
        },

		settings: {
			pages: 'Páginas',
			emailTemplates: 'Templates de E-mails',
			pagesSubTitle:
                "In this area you will be able to change the content of pages, like Terms & Condition and Privacy Policy",
            emailsSubTitle:
                "Setup email templated, used to sent emails to users, and also used to manage staff invitation",
		},

		pages: {
			single: 'Página',
			title: 'Título',
			content: 'Conteúdo',
			new: 'Nova Página',
			isLink: 'Redirecionamento',
		},

		templates: {
			single: 'Template',
		},

		emails: {
            single: 'E-mail',
            tags: 'Tags',
            preview: 'Pré-visualização',
            subject: 'Assunto',
            cc: 'CC',
            templates: 'Templates de E-mail',
            invite: 'Convidar Pessoal',
			edited: 'E-mail template saved successfully.',
        },

		serverErrors: {
            title: 'Erro de Servidor',
            subTitle: 'Não é você, somos nós!',
            refresh: 'Voltar à Página Anterior',
            backToDashboard: 'Ir para o Dashboard',
            wentWrong: 'Algo correu mal.',
        },

		logs: {
			dateRange: 'Date Range',
			userId: 'Id do Utilizador',
			url: 'Url',
			filters: 'Filtros',
			token: 'Token',
			codes: 'Código',
			methods: 'Métodos',
			sources: 'Sources',
			types: 'Tipos',
		},

		notifications: {
			new: 'Nova Notificação',
			title: 'Título da Notificação',
			message: 'Texto da Notificação',
			email: 'Conteúdo do E-mail',
			date: 'Date de Envio',
			sendEmail: 'Enviar E-mail',
			created: 'A notificação foi criada com sucesso.',
			edited: 'A notificação foi editada com sucesso.',
			deleted: 'A notificação foi apagada com sucesso.',
			missingTitle: 'Título é um campo de preenchimento obrigatório.',
			missingDescription: 'Descrição é um campo de preenchimento obrigatório.',
			missingDate: 'Date de Envio é um campo de preenchimento obrigatório.',
			missingEmailContent: 'Conteúdo do E-mail é um campo de preenchimento obrigatório.',
		},


		events: {
			title: 'Nome',
			header: 'Evento',
			image: 'Imagem',
			details: 'Detalhes',
			imagedetail: 'Detalhes da Imagem',
			date: 'Data',
			send: "Enviar",
			sent: "Enviado",
			sendConfirmation: 'Tem a certeza de que pretende enviar o evento aos utilizadores?',
			expired: 'Expirado',
			toExpire: 'Expirar',
			dueDate: "Data Limite",
			link: "Typeform Link",
			confirm: 'Confirm',
			valueBasic: "Valor Básico",
			valuePremium: "Valor Premium",
			sendExpire: 'Tem a certeza de que pretende caducar o evento aos utilizadores?',
			createEvent: 'Criar Evento',
			editEvent: 'Editar Evento',
            invalidName: 'Nome é um campo obrigatório',
			invalidDate: 'Data é um campo obrigatório',
			invalidDescription: 'Descrição é um campo obrigatório',
		},

		users: {
            header: 'Utilizador',
            fullInfo: 'Informações do Utilizadore',
            associateNumber: 'Nº Associado',
        },

		emailTemplates: {
			generalEmail: 'Generic E-mail',
			accountConfirmationCode: 'User Account Confirmation by Code',
			recoverPasswordCode: 'User Password Recovery by Code',
			accountConfirmationLink: 'User Account Confirmation by Link',
			recoverPasswordLink: 'User Password Recovery by Link',
			reservationEmail: 'User Reservation E-mail',
			staffAccountConfirmation: 'Staff Account Confirmation for BO',
			staffRecoverPassword: 'Staff Recover Password for BO',
		},
	},

});
