// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Screen-Event .ant-tabs-tab-btn{user-select:none}.Screen-Event .EventMainImage{height:200px;position:relative;cursor:pointer;border:2px dashed #a6a5a6}.Screen-Event .EventMainImage:hover{border-color:#17445f}.Screen-Event .EventMainImage:hover .GenericImageUploadOverlay:not(.--visible){opacity:1;background-color:#00000080;color:#fff}.Screen-Event .EventMainImage .EventImage{height:100%;width:100%;background-repeat:no-repeat;background-size:contain;background-position:center}.Screen-Event .EventMainImage .GenericImageDelete{display:flex;justify-content:center;align-items:center;position:absolute;right:3px;top:3px;min-height:25px;min-width:25px;border-radius:50%;background-color:#ddd;border:none;box-shadow:0 0 4px 2px #00000080;cursor:pointer;transition:200ms ease-in-out}.Screen-Event .EventMainImage .GenericImageDelete em{font-size:12px}.Screen-Event .EventMainImage .GenericImageDelete:hover{color:red}.Screen-Event .ScheduleOuterContainer{width:100%;overflow-x:auto;overflow-y:hidden}.Screen-Event .ScheduleOuterContainer .ScheduleInnerContainer{width:100%;min-width:1000px}.MapWrapper{border:1px solid #ddd}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#17445f",
	"lightPrimary": "#3f5868",
	"secondaryColor": "#61dafb",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
