// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".status{display:flex;width:100px;height:20px;align-items:center;justify-content:center;border-radius:28px}.status.__pending{background-color:#ff0;cursor:pointer}.status.__pending:hover .resend{scale:1.1}.status.__confirmed{background-color:green;color:#fff}.status.__cancelled{background-color:red;color:#fff}.status .resend{margin-right:5px}.expire{display:flex;width:100px;height:20px;align-items:center;justify-content:center;border-radius:28px}.expire.__toExpire{background-color:#ff0;cursor:pointer}.expire.__toExpire:hover .resend{scale:1.1}.expire.__expired{background-color:red;color:#fff}.expire .resend{margin-right:5px}.resendInvite>.ant-modal-content .ant-modal-body{min-height:0 !important}.resendInvite>.ant-modal-content .ant-modal-footer{display:flex;justify-content:center}@media screen and (max-width: 425px){.resendInvite>.ant-modal-content .ant-modal-footer{flex-direction:column-reverse}}.resendInvite>.ant-modal-content .ant-modal-footer .ant-btn{width:100%;white-space:normal !important;height:auto !important;margin:4px !important}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#17445f",
	"lightPrimary": "#3f5868",
	"secondaryColor": "#61dafb",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
