// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"primaryColor": "#17445f",
	"lightPrimary": "#3f5868",
	"secondaryColor": "#61dafb",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
