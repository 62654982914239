/*
 *
 * PrivacyPolicy
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Strings from 'utils/strings';
import { setLoader } from 'store/actions';
import { API, Endpoints } from 'utils/api';
import './styles.scss';
import logo from 'assets/images/logo_white.png';

export class UserPages extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			isMobile: window.innerWidth <= 768,
		};
		
		this.handleResize = this.handleResize.bind(this);
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize() {
		this.setState({ isMobile: window.innerWidth <= 768 });
	}

	async componentDidMount() {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriPages(match?.params?.id),
		});

		let page;
		if (response.ok) {
			page = response.data.results.pages;
		}

		this.setState({ page });

		dispatch(setLoader(false));
	}

	render() {
		const { isMobile, page } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{page?.title?.pt || Strings.pages.single}</title>
					<meta name='description' content='Description of Privacy Policy' />
				</Helmet>
				<div className={`UserPageWrapper${isMobile ? ' __isMobile' : ''}`}>
					<div className='UserPageLogo'>
						<img src={logo} alt="Login Logo" />
					</div>
					<div style={{ backgroundColor: 'white', height: '100%', paddingLeft: '7.5%', paddingRight: '7.5%', paddingTop: '20px' }}> 
						<h1>{page?.title?.pt}</h1>
						<div style={{ paddingTop: '20px' }} dangerouslySetInnerHTML={{ __html: page?.content?.pt }} />
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(UserPages);
